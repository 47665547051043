<template>
  <footer class="bg-primary-800">
    <div class="max-w-7xl sm:px-6 lg:px-8 px-4 py-12 mx-auto overflow-hidden">
      <nav class="flex flex-wrap justify-center -mx-5 -my-2" aria-label="Footer">
        <div v-for="item in navigation" :key="item.name" class="px-5 py-2">
          <a :href="item.href" class="hover:text-gray-200 text-base text-gray-500" v-if="item.external">
            {{ item.name }}
          </a>
          <Link :href="item.href" class="hover:text-gray-200 text-base text-gray-500" v-else>
            {{ item.name }}
          </Link>
        </div>
      </nav>
      <p class="mt-8 text-sm text-center text-gray-600">&copy; {{ year }} pludoni GmbH</p>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Link, usePage } from "@inertiajs/inertia-vue3"

const { props: pageProps } = usePage()

const navigation = computed(() => [
  { name: "Stellen schalten", href: "/arbeitgeber" },
  { name: "Empfehlungsbund", href: `https://www.empfehlungsbund.de/arbeitgeber?${pageProps.value.utm}`, external: true },
  { name: "Impressum", href: "/impressum" },
  { name: "Datenschutz", href: "/datenschutz" },
])

const year = new Date().getFullYear()
</script>
