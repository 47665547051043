import "~/style.css"

import { startApp, removeModulePrefix } from "~/startApp"
import Layout from '~/components/Layout.vue'
import i18n from '~/utils/i18n'

const siteModules = removeModulePrefix(import.meta.glob(`../pages/**/*.vue`))

startApp(siteModules, Layout, i18n)

