<template>
  <nav class="bg-primary border-secondary border-b-4 border-solid">
    <div class="max-w-7xl sm:px-6 lg:px-8 px-2 mx-auto">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex items-center flex-1">
          <div class="flex items-center flex-shrink-0 text-lg font-semibold text-gray-200">
            <Link href="/" class="hover:contrast-125 hover:brightness-125 transition-all">
              <img :src="logo" class="w-auto h-8" :alt="settings.name" />
            </Link>
          </div>
          <div class="sm:block sm:ml-3  hidden">
            <Link class="md:flex hidden px-2 space-x-4 font-mono text-sm text-gray-200 hover:contrast-125 transition-all" href="/">
              {{ slogan }}
            </Link>
          </div>
        </div>
        <div class="flex gap-1">
          <Button href="?locale=de" title="Auf Deutsch" :active='pageProps.locale == "de"'>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 5 3">
              <desc>Flag of Germany</desc>
              <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000" />
              <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00" />
              <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00" />
            </svg>
          </Button>
          <Button href="?locale=en" title="Read in English" :active='pageProps.locale == "en"'>
            <img :src="englishLangugeSvg" class="h-6 w-6" alt="English/US Flag" />
          </Button>
          <Button href="?locale=uk" title="Читайте українською" :active='pageProps.locale == "uk"'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 800" class="h-6 w-6">
              <rect width="1200" height="800" fill="#005BBB" />
              <rect width="1200" height="400" y="400" fill="#FFD500" />
            </svg>
          </Button>
        </div>
        <div class="sm:static sm:inset-auto sm:ml-6 sm:pr-0 absolute inset-y-0 right-0 md:flex items-center pr-2 hidden">
          <Button to="/arbeitgeber" variant="dark" size="sm" :active="isActive('/arbeitgeber')">
            {{ $t("components.navbar.anzeige_schalten") }}
          </Button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Link, usePage } from "@inertiajs/inertia-vue3"
import { Button } from "@/elements"
import { computed } from "vue"
import logo from "@/images/icon-white.png"
import englishLangugeSvg from "@/images/english_flag.svg"
import settings from "@/generated/settings.json"

const { props: pageProps } = usePage()

const slogan = computed(() => {
  if (pageProps.value.locale === "de") {
    return settings.slogan_de
  }
  if (pageProps.value.locale === "en") {
    return settings.slogan_en
  }
  if (pageProps.value.locale === "uk") {
    return settings.slogan_uk
  }
})

const isActive = (path: string) => {
  return window && path === window.location.pathname
}
// const activeClasses = "ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
</script>
