export default {
  "de": {
    "components": {
      "apply_card": {
        "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Angaben sind noch unvollständig:"])},
        "external_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WICHTIGER HINWEIS: Die ", _interpolate(_named("company")), " nutzt ein Bewerbermanagementsystem. Sie werden mit Klick auf den Button „Bewerben“ direkt dorthin geleitet. Dazu öffnet sich ein neues Fenster/Tab."])},
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bewerben Sie sich jetzt bei der ", _interpolate(_named("company")), " als ", _interpolate(_named("job")), "."])},
        "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Arbeitgeber bittet Sie, in der Zwischenzeit auf kanaleo ein anonymes Feedback zu geben, wie Sie auf die Stelle aufmerksam geworden sind."])},
        "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postanschrift"])},
        "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben sich bereits am ", _interpolate(_named("date")), " auf diese Stelle beworben."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung abschicken"])},
        "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung wurde erfolgreich versendet!"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerben"])}
      },
      "empfehlungscodes_field": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode hinzufügen"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Empfehlungscode erhalten nur erstklassige Fachkräfte. Die Unternehmer der beteiligten Community-Portale verständigen sich regelmäßig,\nwer eine Empfehlung verdient hat. Vertrauen Sie dem Urteil der Experten. Überzeugen Sie sich vom strengen Regelwerk in den\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.empfehlungsbund.de/faq#Bewerber\" target=\"_blank\">FAQs des Empfehlungsbunds</a>.\nNutzen Sie den Empfehlungscode für Ihren beruflichen Erfolg.\n"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode hinzufügen"])},
        "recommended_by": {
          "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Empfohlener Kandidat, von ", _interpolate(_named("company"))])},
          "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Interessanter Kandidat, von ", _interpolate(_named("company"))])}
        },
        "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Referenz verwenden: Die Firma bei der mich hier bewerbe, darf gern die Organisation, die mir den Empfehlungscode ausgestellt hat, während meiner Bewerbung zu den Gründen der Empfehlung befragen."])}
      },
      "filter_sidebar": {
        "portal_types": {
          "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatik, Softwareentwicklung, etc."])},
          "MINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrotechnik, Maschinenbau, etc."])},
          "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BWL, VWL, Recht, etc."])},
          "SANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärzte, Therapeuten, Pfleger, etc."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themen"])}
        },
        "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
        "query_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchbegriffe..."])},
        "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])}
      },
      "job_card": {
        "full_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollzeit"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
        "minijob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mini-Job"])},
        "more_than_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 30 Tage"])},
        "part_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilzeit"])},
        "remote_type": {
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angabe"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home-Office Option"])}
        },
        "salary_interval": {
          "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Std."])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Monat"])},
          "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Jahr"])}
        },
        "shift_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schichtarbeit"])},
        "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befristet"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung per WhatsApp"])}
      },
      "navbar": {
        "anzeige_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeige schalten"])}
      },
      "subscribe_newsletter_bar": {
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten Sie neue passende Stellenangebote zu Ihren oben eingestellten Suchbegriffen. Diesen Newsletter können Sie jederzeit abbestellen.\nWeitere Hinweise finden Sie in unseren <a href=\"/datenschutz\" target=\"_blank\">Datenschutzhinweisen</a>.\n"])},
        "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Stellen per E-Mail erhalten"])},
        "new_jobs_per_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Stellen per E-Mail erhalten"])},
        "subscribe_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren"])},
        "subscribed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir haben eine E-Mail an ", _interpolate(_named("email")), " geschickt. Bitte klicken Sie auf den darin enthaltenen Link um den Newsletter-Agenten zu starten. Bitte schauen Sie ggf. auch im Ordner Spam/Junk nach."])}
      }
    },
    "elements": {
      "breadcrumbs": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home-Office-Jobs"])}
      },
      "pagination": {
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zeige <span class=\"font-medium\">", _interpolate(_named("from")), "</span> bis <span class=\"font-medium\">", _interpolate(_named("to")), "</span> von <span class=\"font-medium\">", _interpolate(_named("count")), "</span> Stellen"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
      },
      "upload_field": {
        "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird hochgeladen:"])},
        "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder hier herziehen und ablegen"])},
        "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Dokument hochladen"])}
      }
    },
    "form": {
      "language_skills": {
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundkenntnisse (A1-A2)"])},
        "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Kenntnisse (B1-B2)"])},
        "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr gute Kenntnisse (C1-C2)"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht notwendig"])}
      },
      "messages": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige E-Mail-Adresse"])},
        "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen mindestens eine Datei hochladen"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " muss mindestens ", _interpolate(_named("min")), " Zeichen lang sein"])},
        "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " muss angegeben werden"])}
      },
      "properties": {
        "application_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse für Bewerbungseingänge"])},
        "category_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenkategorie/Beruf"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar/Anschreiben"])},
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitgeber"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellenbeschreibung"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungsunterlagen"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "language_level_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englische Sprachkenntnisse"])},
        "language_level_german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsche Sprachkenntnisse"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsort (ggf. mit Stadtteil)"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer für Rückfragen"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
        "qualifikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualifikation"])},
        "remote_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zum Homeoffice/Heimarbeit"])},
        "salary_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angabe zur Bezahlung"])},
        "salary_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt/Lohn (EUR, Bis)"])},
        "salary_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt/Lohn (EUR, Von)"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße & Hausnummer"])},
        "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer zur telefonischen Auskunft oder Bewerbung"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellentitel/Beruf"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link zum Bewerbungsformular Ihres BMS"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung per WhatsApp möglich"])}
      },
      "remote_type": {
        "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reine Telearbeit / 100% Homeoffice"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Vor-Ort"])},
        "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilweise Homeoffice / alternierende Telearbeit"])}
      },
      "salary_interval": {
        "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Stunde"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Monat"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Ohne Angabe)"])},
        "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Jahr"])}
      }
    },
    "models": {
      "fallback_portal_search": {
        "jobs_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " Stellen ", _interpolate(_named("q")), " gefunden"])},
        "nothing_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Stellenangebote gefunden"])}
      }
    },
    "pages": {
      "arbeitgeber": {
        "angaben_zur_stelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zur Stelle"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zur Bewerbung"])},
        "arbeitszeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeit"])},
        "bitte_geben_sie_den_gehaltsbereich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Gehaltsbereich/den Lohn an. Falls das Gehalt fest ist, geben Sie bei Von und Bis denselben Wert an. Angabe in Brutto/EUR."])},
        "full_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollzeit"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie kostenfrei ein Stellenangebot für 30 Tage auf uajobs.de anbieten. Nach Abschicken des Formulars erhalten Sie umgehend eine\nE-Mail mit Bestätigungslink. Erst nach der Bestätigung geht die Stelle live.\n"])},
        "minijob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mini-Job"])},
        "part_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilzeit"])},
        "qualifikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anforderungen"])},
        "shift_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schichtarbeit/Nachtarbeit/Wochenende"])}
      },
      "jobs": {
        "show": {
          "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt bewerben"])},
          "apply_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per E-Mail bewerben"])},
          "apply_with_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf dem Online-Formular des Anbieters bewerben"])},
          "goto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die vollständige Stellenanzeige und Informationen zur Bewerbung finden Sie auf ", _interpolate(_named("source")), "."])}
        },
        "show_invisible": {
          "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Stelle ist nicht mehr verfügbar."])}
        }
      }
    }
  }
}