export default {
  "uk": {
    "components": {
      "apply_card": {
        "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наступна інформація все ще неповна:"])},
        "external_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ВАЖЛИВА ПРИМІТКА. ", _interpolate(_named("company")), " використовує систему керування заявниками. Ви будете перенаправлені туди, натиснувши кнопку «Застосувати». Відкриється нове вікно/вкладка."])},
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Подайте заявку зараз до ", _interpolate(_named("company")), " як ", _interpolate(_named("job")), "."])},
        "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тим часом роботодавець просить вас надати анонімний відгук на kanaleo про те, як ви дізналися про посаду."])},
        "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поштова адреса"])},
        "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ви вже подали заявку на цю посаду ", _interpolate(_named("date")), "."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подати заявку"])},
        "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявку успішно відправлено!"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Застосувати"])}
      },
      "empfehlungscodes_field": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте реферальний код"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тільки першокласні професіонали отримують реферальний код. Підприємці залучених порталів громад регулярно спілкуються,\nхто заслуговує на рекомендацію. Довіртеся висновкам експертів. Переконайтеся в суворих правилах\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.fehlungsbund.de/faq#Bewerber\" target=\"_blank\">Поширені запитання Асоціації рекомендацій</a> .\nВикористовуйте реферальний код для свого професійного успіху.\n"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте реферальний код"])},
        "recommended_by": {
          "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Рекомендований кандидат, від ", _interpolate(_named("company"))])},
          "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цікавий кандидат, від ", _interpolate(_named("company"))])}
        },
        "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Використовуйте як довідник: компанія, до якої я подаю заявку, може запитати організацію, яка надала мені код рекомендації, про причини направлення під час моєї заявки."])}
      },
      "filter_sidebar": {
        "portal_types": {
          "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT, розробка програмного забезпечення тощо."])},
          "MINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електротехніка, машинобудування тощо."])},
          "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бізнес-адміністрування, економіка, право тощо."])},
          "SANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лікарі, терапевти, медсестри тощо."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["предметів"])}
        },
        "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розташування"])},
        "query_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошукові терміни..."])},
        "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шукати"])}
      },
      "job_card": {
        "full_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повний день"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мову"])},
        "minijob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["міні робота"])},
        "more_than_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 30 днів"])},
        "part_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неповний робочий день"])},
        "remote_type": {
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дистанційно"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає інформації"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гібридний"])}
        },
        "salary_interval": {
          "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/год."])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Місяць"])},
          "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Рік"])}
        },
        "shift_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["змінна робота"])},
        "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обмежений"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сьогодні"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка через WhatsApp"])}
      },
      "navbar": {
        "anzeige_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["змінити оголошення"])}
      },
      "subscribe_newsletter_bar": {
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримуйте нові відповідні пропозиції роботи за пошуковими термінами, які ви ввели вище. Ви можете скасувати підписку на цю розсилку в будь-який час.\nДодаткову інформацію можна знайти в нашій <a href=\"/datenschutz\" target=\"_blank\">Політиці конфіденційності</a>.\n"])},
        "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувати нові вакансії на електронну пошту"])},
        "new_jobs_per_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримувати нові вакансії на електронну пошту"])},
        "subscribe_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підпишіться на"])},
        "subscribed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ми надіслали електронний лист на адресу ", _interpolate(_named("email")), ". Будь ласка, натисніть посилання, що міститься в ньому, щоб запустити агента інформаційних бюлетенів. Якщо потрібно, перевірте також папку Спам/Небажання."])}
      }
    },
    "elements": {
      "breadcrumbs": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["робочі місця в домашньому офісі"])}
      },
      "pagination": {
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показати <span class=\"font-medium\">", _interpolate(_named("from")), "</span> до <span class=\"font-medium\">", _interpolate(_named("to")), "</span> від <span class=\"font-medium\">", _interpolate(_named("count")), " </span> цифри"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["далі"])}
      },
      "upload_field": {
        "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження:"])},
        "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або перетягніть сюди"])},
        "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажте документ"])}
      }
    },
    "form": {
      "language_skills": {
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базові знання (A1-A2)"])},
        "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розширені знання (B1-B2)"])},
        "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дуже добре знання (C1-C2)"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непотрібні"])}
      },
      "messages": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Це недійсна адреса електронної пошти"])},
        "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви повинні завантажити принаймні один файл"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " має містити щонайменше ", _interpolate(_named("min")), " символів"])},
        "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Необхідно вказати ", _interpolate(_named("property"))])}
      },
      "properties": {
        "application_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса електронної пошти для вхідних заяв"])},
        "category_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категорія роботи/професія"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["місто"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["коментар/супровідний лист"])},
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["роботодавець"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описання роботи"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["електронна пошта"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["документи заявки"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
        "language_level_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["володіння англійською мовою"])},
        "language_level_german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Знання німецької мови"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["прізвище"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["робоча адреса"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["телефон"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поштовий індекс"])},
        "qualifikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кваліфікація"])},
        "remote_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про домашній офіс/роботу з дому"])},
        "salary_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про оплату"])},
        "salary_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заробітна плата (EUR, до)"])},
        "salary_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарплата (євро, від)"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вулиця, номер будинку"])},
        "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону для отримання інформації або заявки"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посада/професія"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на форму заявки вашого BMS"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можлива заявка через WhatsApp"])}
      },
      "remote_type": {
        "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чиста дистанційна робота / 100% домашній офіс"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тільки на сайті"])},
        "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частковий домашній офіс / чергування дистанційної роботи"])}
      },
      "salary_interval": {
        "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В годину"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На місяць"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Без специфікації)"])},
        "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В рік"])}
      }
    },
    "models": {
      "fallback_portal_search": {
        "jobs_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Знайдено ", _interpolate(_named("count")), " місць ", _interpolate(_named("q"))])},
        "nothing_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вакансій не знайдено"])}
      }
    },
    "pages": {
      "arbeitgeber": {
        "angaben_zur_stelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["інформація про посаду"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація про заявку"])},
        "arbeitszeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["робочі години"])},
        "bitte_geben_sie_den_gehaltsbereich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вкажіть, будь ласка, діапазон/оклад. Якщо зарплата фіксована, введіть однакове значення для параметрів Від і До. Специфікація в брутто/євро."])},
        "full_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повний день"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тут ви можете запропонувати пропозицію роботи на 30 днів безкоштовно на uajobs.de. Після надсилання форми ви одразу отримаєте\nЕлектронний лист із посиланням для підтвердження. Робота запускається лише після підтвердження.\n"])},
        "minijob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["міні робота"])},
        "part_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неповний робочий день"])},
        "qualifikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вимоги"])},
        "shift_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінна робота / нічна робота / вихідні"])}
      },
      "jobs": {
        "show": {
          "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подати зараз"])},
          "apply_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подайте заявку електронною поштою"])},
          "apply_with_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подайте заявку на онлайн-форму постачальника"])},
          "goto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Повне оголошення про вакансію та інформацію про те, як подати заявку, можна знайти на сторінці ", _interpolate(_named("source")), "."])}
        },
        "show_invisible": {
          "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця посада більше недоступна."])}
        }
      }
    }
  }
}