import { h, defineComponent } from "vue"

export default defineComponent({
  name: "JsonLd",
  props: {
    jsonLd: {
      type: Object,
      required: true,
    },
  },
  render() {
    return h("script", {
      type: "application/ld+json",
      innerHTML: JSON.stringify(this.jsonLd, null, 2),
    })
  },
})
