import { createI18n } from 'vue-i18n'

import en from '~/generated/locales/en'
import de from '~/generated/locales/de'
import uk from '~/generated/locales/uk'

const i18n = createI18n({
  locale: 'de',
  //fallbackLocale: 'en',
  allowComposition: true,
  messages: {
    ...de,
    ...en,
    ...uk,
  },
})

window.i18n = i18n.global

export default i18n
