export default {
  "en": {
    "components": {
      "apply_card": {
        "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following information is still incomplete:"])},
        "external_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IMPORTANT NOTE: The ", _interpolate(_named("company")), " uses an applicant management system. You will be directed there by clicking on the “Apply” button. A new window / tab opens for this purpose."])},
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Apply now to the ", _interpolate(_named("company")), " as a ", _interpolate(_named("job")), "."])},
        "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime, the employer asks you to provide anonymous feedback on kanaleo on how you became aware of the position."])},
        "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailing address"])},
        "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You already applied for this position on ", _interpolate(_named("date")), "."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
        "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application was sent successfully!"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
      },
      "empfehlungscodes_field": {
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add referral code"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only first-class professionals receive a referral code. The entrepreneurs of the community portals involved communicate regularly\nwho deserves a recommendation. Trust the judgment of the experts. Convince yourself of the strict rules in the\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.empfehlungsbund.de/faq#Bewerber\" target=\"_blank\"> FAQs of the Empfehlungsbund </a>.\nUse the referral code for your professional success.\n"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add referral code"])},
        "recommended_by": {
          "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recommended candidate, by ", _interpolate(_named("company"))])},
          "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Interesting candidate, from ", _interpolate(_named("company"))])}
        },
        "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use as a reference: The company I'm applying to here is welcome to ask the organization that issued me the referral code about the reasons for the referral during my application."])}
      },
      "filter_sidebar": {
        "portal_types": {
          "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Computer science, software development, etc."])},
          "MINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrical engineering, mechanical engineering, etc."])},
          "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business administration, economics, law, etc."])},
          "SANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctors, therapists, nurses, etc."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjects"])}
        },
        "position_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "query_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search terms ..."])},
        "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      },
      "job_card": {
        "full_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full time"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "minijob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mini job"])},
        "more_than_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 30 days"])},
        "part_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part time"])},
        "remote_type": {
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])}
        },
        "salary_interval": {
          "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Hours."])},
          "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Month"])},
          "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Year"])}
        },
        "shift_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift work"])},
        "temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application via WhatsApp"])}
      },
      "navbar": {
        "anzeige_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post a job free of charge"])}
      },
      "subscribe_newsletter_bar": {
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new job offers that match your search terms above. You can unsubscribe from this newsletter at any time.\nFurther information can be found in our <a href=\"/datenschutz\" target=\"_blank\"> data protection information </a>.\n"])},
        "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new jobs by email"])},
        "new_jobs_per_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive new jobs by email"])},
        "subscribe_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
        "subscribed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We have sent an email to ", _interpolate(_named("email")), ". Please click on the link contained therein to start the newsletter agent. If necessary, please also look in the Spam / Junk folder."])}
      }
    },
    "elements": {
      "breadcrumbs": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home office jobs"])}
      },
      "pagination": {
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show <span class=\"font-medium\"> ", _interpolate(_named("from")), " </span> to <span class=\"font-medium\"> ", _interpolate(_named("to")), " </span> from <span class=\"font-medium\"> ", _interpolate(_named("count")), " </span> jobs"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "upload_field": {
        "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is uploaded:"])},
        "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop here"])},
        "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a document"])}
      }
    },
    "form": {
      "language_skills": {
        "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic knowledge (A1-A2)"])},
        "b1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced knowledge (B1-B2)"])},
        "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very good knowledge (C1-C2)"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not needed"])}
      },
      "messages": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is not a valid email address"])},
        "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to upload at least one file"])},
        "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " must be at least ", _interpolate(_named("min")), " characters long"])},
        "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " must be specified"])}
      },
      "properties": {
        "application_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address for incoming applications"])},
        "category_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Category/Profession"])},
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City/Municipality"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment / Cover letter"])},
        "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employer / Organisation"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application documents"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Given Name"])},
        "language_level_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English language skills"])},
        "language_level_german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German language skills"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name / family name"])},
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["working address"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
        "qualifikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qualification"])},
        "remote_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information on home office/working from home"])},
        "salary_interval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
        "salary_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary/wage (EUR, max)"])},
        "salary_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary/wage (EUR, min)"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street address"])},
        "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number for telephone information or application"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title/Profession"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to the application form of your BMS"])},
        "whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application via WhatsApp possible"])}
      },
      "remote_type": {
        "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pure telework / 100% home office"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On site only"])},
        "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial home office / alternating telework"])}
      },
      "salary_interval": {
        "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per hour"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per month"])},
        "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Without specification)"])},
        "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per year"])}
      }
    },
    "models": {
      "fallback_portal_search": {
        "jobs_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " vacancies ", _interpolate(_named("q")), " found"])},
        "nothing_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vacancies found"])}
      }
    },
    "pages": {
      "arbeitgeber": {
        "angaben_zur_stelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the position"])},
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information on the application"])},
        "arbeitszeit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours"])},
        "bitte_geben_sie_den_gehaltsbereich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please indicate salary range/salary. If the salary is fixed, enter the same value for From and To. Specification in gross/EUR."])},
        "full_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full time"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you can offer a job offer for 30 days free of charge on uajobs.de. After submitting the form you will immediately receive an\nEmail with confirmation link. The job only goes live after confirmation.\n"])},
        "minijob": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mini job"])},
        "part_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part time"])},
        "qualifikation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requirements"])},
        "shift_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift work/night work/weekend"])}
      },
      "jobs": {
        "show": {
          "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])},
          "apply_with_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply by email"])},
          "apply_with_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply on the provider's online form"])},
          "goto": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The complete job advertisement and information on how to apply can be found at ", _interpolate(_named("source")), "."])}
        },
        "show_invisible": {
          "not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This position is no longer available."])}
        }
      }
    }
  }
}